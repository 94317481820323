<template>
  <div class="wrap" id="moondayNote">
    <div class="header">
      <h2 class="st_title hidden-pad-down">
        <!-- <img src="@/assets/月亮日記/月亮日記標題圖片.svg" alt="" /> -->
        月亮日記
      </h2>
      <div class="filterBar">
        <div class="editMoonNote_btn" @click="editMoonNoteHandler()">
          <img
            class="hidden-mobile-down"
            src="@/assets/icon-edit_white.svg"
            alt=""
          />
          <img
            class="hidden-mobile-up"
            src="@/assets/icon-edit_gold.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    <!---------------- Moonday Note-------------- -->
    <div class="moondayNoteDetail" v-if="moondayNoteInfo">
      <div
        class="moondayNoteDetail__header"
        :class="{ infoWrap: splitMoods.length > 2 }"
      >
        <div class="moondayNoteDetail__header__moodsGroup">
          <div
            class="moondayNoteDetail__header__moodsGroup__mood"
            v-for="(mood, index) in splitMoods"
            :key="index"
            :style="`background-color:${
              emotionList.find((e) => e.id === mood).color
            } `"
          >
            <img
              :src="emotionList.find((e) => e.id === mood).active_icon"
              alt=""
            />
          </div>
        </div>
        <div class="moondayNoteDetail__header__content">
          <div class="moondayNoteDetail__header__content__title">
            月亮{{ Constellation_CN }}日
          </div>
          <div>{{ `${moondayNoteInfo.Date} ${moondayNoteInfo.Time}` }}</div>
        </div>
        <img class="pic1" src="@/assets/月亮日記/筆記裝飾_月亮.svg" alt="" />
        <img class="pic2" src="@/assets/月亮日記/筆記裝飾星星大.svg" alt="" />
        <img class="pic3" src="@/assets/月亮日記/筆記裝飾星星小.svg" alt="" />
        <img
          class="pic4"
          src="@/assets/月亮日記/筆記裝飾_月亮_手機.svg"
          alt=""
        />
      </div>
      <!-- <div class="moondayNoteDetail__line"></div> -->
      <div class="moondayNoteDetail__body">
        <h2>我的心情</h2>
        <p v-html="replaceBr(moondayNoteInfo.MoodContent)"></p>
        <h2>月亮日記</h2>
        <p v-html="replaceBr(moondayNoteInfo.Content)"></p>
      </div>
      <div class="moondayNoteDetail__share">
        <div
          class="moondayNoteDetail__share__btn"
          @click="openDownloadImg = true"
        >
          <span>分享</span>
          <img src="@/assets/月亮日記/分享.svg" alt="" />
        </div>
      </div>
      <div class="moondayNoteDetail__btn">
        <div
          class="moondayNoteDetail__btn__pre"
          :class="{ disable: !moondayNoteInfo.PreviousMoonNoteID }"
          @click="toNote(false)"
        >
          上一則
        </div>
        <div
          class="moondayNoteDetail__btn__next"
          :class="{ disable: !moondayNoteInfo.NextMoonNoteID }"
          @click="toNote(true)"
        >
          下一則
        </div>
      </div>
    </div>
    <div
      class="moondayNoteGoBack hidden-pad-up"
      @click="$router.push('/moonday-list')"
    >
      返回日記列表
    </div>

    <!-- MoondayDownload -->
    <MoondayDownload
      v-if="moondayNoteInfo && openDownloadImg"
      :moondayNoteInfo="moondayNoteInfo"
      @close="openDownloadImg = false"
    />
  </div>
</template>

<script>
import MoondayDownload from "@/components/月亮日記/MoondayDownload.vue";
import emotionList from "@/js/emotionList.js";

export default {
  components: {
    MoondayDownload,
  },
  data() {
    return {
      emotionList,
      moondayNoteInfo: null,
      Constellation_CN: "",
      loading: false,
      openDownloadImg: false,
    };
  },
  created() {
    this.$store.commit("setLoading", true);
    setTimeout(() => {
      this.fetchData();
      this.$store.commit("setLoading", false);
    }, 1000);
  },
  computed: {
    MoonNoteID() {
      return this.$route.params.MoonNoteID;
    },
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    splitMoods() {
      return this.moondayNoteInfo.Moods.split("|");
    },
  },
  methods: {
    async fetchData() {
      try {
        let { data } = await this.$API.GET_MoonDayNoteDetail(this.MoonNoteID);

        this.moondayNoteInfo = data.Data;
        this.Constellation_CN = this.queryitemConstellationCN(
          data.Data.Constellation
        );
        this.moondayNoteInfo.Constellation_CN = this.Constellation_CN;

        this.$Astro.find((item) => item.en === data.Data.Constellation).cn;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    editMoonNoteHandler() {
      this.$store.commit("setMoondayEdit", {
        ...this.moondayNoteInfo,
        Constellation_CN: this.Constellation_CN,
        Date: moment(new Date(this.moondayNoteInfo.Date)).format("YYYY-MM-DD"),
        Time: this.moondayNoteInfo.Time,
      });
      this.$router.push("/moonday-edit");
    },
    queryitemConstellationCN(constellation_en) {
      let astro = this.$Astro.find((item) => constellation_en === item.en);
      return astro.cn;
    },
    toNote(direction) {
      if (this.loading) return;
      this.loading = true;
      if (direction) {
        this.$router.push(
          `/moonday-note/${this.moondayNoteInfo.NextMoonNoteID}`
        );
      } else {
        this.$router.push(
          `/moonday-note/${this.moondayNoteInfo.PreviousMoonNoteID}`
        );
      }
      this.fetchData();
    },
    replaceBr(str) {
      return str.replace(/\n/g, "<br />");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#moondayNote {
  padding-top: 20px;
  width: 100%;
  max-width: 989px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    @include mobile {
      height: 0;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      img {
        width: 54px;
        margin-right: 16px;
        @include mobile {
          width: 30px;
          margin-right: 5px;
        }
      }
    }
    .filterBar {
      margin: 42px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        margin-top: 0;
      }
      .editMoonNote_btn {
        @include btn(40px, 40px, $theme-color-2, #fff);
        border-radius: 50%;
        margin-left: 30px;
        color: #fff;
        @include mobile {
          position: fixed;
          top: 12px;
          right: 12px;
          z-index: 200;
          background-color: transparent;
          color: $theme-color-2;
        }
        img {
          width: 90%;
        }
      }
    }
  }
  .moondayNoteDetail {
    max-width: 640px;
    margin: 0 auto;
    padding: 24px 40px 36px;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.3), -4px -4px 10px #fff,
      -4px -4px 16px #fff;
    border-radius: 50px;
    @include mobile {
      padding: 12px 20px 28px;
      border-radius: 20px;
    }
    &__header {
      height: 110px;
      padding: 28px 32px 21px;
      position: relative;
      display: flex;
      &.infoWrap {
        height: auto;
        flex-direction: column-reverse;
        .moondayNoteDetail__header__content {
          margin-bottom: 12px;
        }
      }
      @include mobile {
        font-size: 13px;
        line-height: 18px;
        height: 92px;
        padding: 21px 20px;
        &.infoWrap {
          padding: 8px 22px;
          .moondayNoteDetail__header__content {
            margin-bottom: 8px;
          }
        }
        &__title {
          font-size: 20px;
          line-height: 21px;
          margin-bottom: 8px;
        }
        &__mood {
          width: 28px;
          height: 28px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &__content {
        font-weight: 500;
        color: #868686;
        font-size: 16px;
        @include mobile {
          font-size: 13px;
          letter-spacing: 0.52px;
        }
        &__title {
          color: $theme-color-2;
          font-size: 28px;
          line-height: 28px;
          margin-bottom: 12px;
          @include mobile {
            font-size: 13px;
            font-size: 20px;
            margin-bottom: 8px;
          }
        }
      }
      &__moodsGroup {
        display: flex;
        &__mood {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          @include center;
          margin-right: 8px;
          @include mobile {
            width: 28px;
            height: 28px;
            margin-right: 4px;
          }
          img {
            width: 21px;
            height: 21px;
            @include mobile {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      & > img {
        position: absolute;
      }
      .pic1 {
        height: 124px;
        width: 124px;
        top: 0;
        right: 0;
        @include mobile {
          display: none;
        }
      }
      .pic4 {
        display: none;
        top: 0;
        right: 0;
        @include mobile {
          display: inline-block;
          height: 106px;
          width: 106px;
        }
      }
      .pic2 {
        width: 16px;
        height: 16px;
        top: 20px;
        left: 0;
      }
      .pic3 {
        width: 10px;
        height: 10px;
        bottom: 20px;
        left: 4px;
      }
    }
    &__line {
      height: 3px;
      width: 100%;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08) inset;
    }
    &__body {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #868686;
      padding: 28px 0 0;
      word-break: break-all;
      @include mobile {
        font-size: 16px;
      }
      h2 {
        font-weight: 500;
        margin-bottom: 4px;
        color: #ab956c;
      }
      p {
        margin-bottom: 28px;
        @include mobile {
          margin-bottom: 24px;
        }
      }
    }
    &__share {
      display: flex;
      justify-content: flex-end;
      &__btn {
        width: 86px;
        height: 36px;
        border-radius: 12px;
        @include center;
        color: #798da5;
        font-size: 14px;
        box-shadow: 2.5px 2.5px 7.5px 0px rgba(0, 0, 0, 0.25),
          -1.25px -1.25px 5px 0px #fff, -1.25px -1.25px 5px 0px #fff;
        cursor: pointer;
        span {
          margin-right: 4px;
        }
      }
    }
    &__btn {
      margin-top: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        justify-content: space-between;
      }
      &__next {
        @include btn-default(176px, 56px, 20px);
        font-size: 20px;
        line-height: 30px;
        border: 2px solid rgba(121, 141, 165, 0.3);
        margin-right: 16px;
        text-align: center;
        color: #ffffff;
        margin-left: 15px;
        @include mobile {
          height: 40px;
          font-size: 14px;
          line-height: 21px;
        }
        &.disable {
          background-color: #9b9b9b;
          color: #fff;
          font-weight: 400;
          pointer-events: none;
        }
      }
      &__pre {
        @include btn-default(176px, 56px, 20px);
        font-size: 20px;
        line-height: 30px;
        transition: 0.2s;
        color: #9b9b9b;
        background: transparent;
        border: 1px solid #9b9b9b;
        &:hover {
          color: #9b9b9b;
          background: transparent;
          border: 1px solid #9b9b9b;
        }
        &.disable {
          pointer-events: none;
          opacity: 0.4;
        }
        @include mobile {
          height: 40px;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .moondayNoteGoBack {
    margin-top: 36px;
    color: #798da5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
</style>

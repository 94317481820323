<template>
  <div class="wrap" id="selectTarget">
    <div class="header">
      <h2 class="st_title">
        <img src="@/assets/占星小鋪/friend.svg" alt="" />
        選擇星盤好友
      </h2>
      <div class="filterBar">
        <label>
          <img
            class="serachIcon"
            src="../../assets/占星小鋪/search.svg"
            alt=""
          />
          <input
            type="text"
            placeholder="搜尋星盤名稱"
            v-model="filterKey"
            @keyup.enter="filterHandler"
          />
        </label>
        <button class="filter_btn" @click="filterHandler">搜尋</button>
        <div class="addFriends_btn" @click="toAddFriend()">
          <img src="../../assets/占星小鋪/add friend.svg" alt="" />
        </div>
      </div>
    </div>
    <!-- 列表6/27改成沒有區分 -->
    <div class="st_list_group unlock_area">
      <v-row>
        <v-col
          cols="12"
          v-for="item in List_filter"
          :key="item.AstrolabeFileId"
        >
          <FriendCard
            class="TargetCard-space mx-auto"
            @clicked="selectHandler(item)"
            @next="next(item, $event)"
            @delete="deleteFriend(item)"
            @edit="editHandler(item)"
            :closeEditMode="closeEditMode"
            :selected="item.AstrolabeFileId == selectObj.AstrolabeFileId"
            :data="item"
          >
          </FriendCard>
        </v-col>
      </v-row>
    </div>
    <!-- 刪除好友 -->
    <transition name="fade">
      <div
        class="dialog__overlay"
        v-if="deleteDialog"
        @click="deleteDialog = false"
      >
        <div class="dialog__box" @click.stop="deleteDialog = true">
          <div class="dialog__title">刪除好友</div>
          <div class="dialog__subtitle">確定要刪除該好友資料嗎？</div>
          <div class="dialog__btn">
            <div class="btn cancel" @click.stop="deleteDialog = false">
              取消
            </div>
            <div class="btn confirm" @click.stop="deleteFriendForSure()">
              確認
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 刪除顯示泡泡 -->
    <transition name="fade">
      <div class="toast" v-if="showDelete">該好友資料已刪除</div>
    </transition>
  </div>
</template>

<script>
import FriendCard from "../../components/會員相關/FriendCard";
export default {
  components: {
    FriendCard,
  },
  data() {
    return {
      List_all: [],
      List_filter: [],
      filterKey: "",
      selectObj: { AstrolabeFileId: null },
      friendBeDelete: null,
      showDelete: false,
      deleteDialog: false,
      closeEditMode: false,
    };
  },
  created() {
    this.$API.Get_MemberInfo().then((data) => {
      window.localStorage["loginIndex"] = data.Data.MemberId;
      this.$store.commit("set_userData", data.Data);
      this.init();
    });
  },
  mounted() {
    let friendState = window.localStorage.getItem("friendState");
    window.localStorage.removeItem("friendState");
    switch (friendState) {
      case "createNew":
        this.$store.commit("update_toast", ["已新增好友星盤"]);
        break;
      case "Edit":
        this.$store.commit("update_toast", ["該筆資料已修改"]);
        break;
    }
  },
  methods: {
    init() {
      if (this.$route.query.mode) {
        this.closeEditMode = true;
      } else {
        this.closeEditMode = false;
      }
      this.List_all = [];
      this.List_filter = [];

      this.$store.state.userData.AstrolabeFriendLists.forEach(
        (AstrolabeFriend) => {
          this.List_all.push(AstrolabeFriend);
        }
      );
      this.List_all = this.List_all;
      this.List_filter = [...this.List_all];
    },
    filterHandler() {
      this.List_filter = this.List_all.filter(
        (item) =>
          item.Name.toUpperCase().indexOf(this.filterKey.toUpperCase()) > -1
      );
    },
    selectHandler(Astrolabe) {
      this.selectObj = Astrolabe;
    },
    next(Astrolabe, service) {
      this.$API.Get_AstrolabeFile(Astrolabe.AstrolabeFileId).then((data) => {
        this.$store.commit("set_nowUserData", data.Data);

        switch (service) {
          case "宮位":
            return this.$router.push("/astrolabe");
          case "推運功能":
            return this.$router.push("/timelineAstrolabe");
          default:
            if (this.$route.query.mode === "timeLineMode") {
              return this.$router.push("/timelineAstrolabe");
            }
            if (this.$route.query.mode == "astrometryMode") {
              return this.$router.push("/astrometry");
            }
            return this.$router.push("/astrolabe");
        }
      });
    },
    toAddFriend() {
      this.$router.push("/friend-create");
    },
    //編輯好友
    editHandler(Astrolabe) {
      if (Astrolabe.Relationship === "R0" && Astrolabe.IsBelongToMember) {
        //自己
        window.localStorage.setItem("editMe", Astrolabe.AstrolabeFileId);
        this.$router.push({ path: "/member-edit" });
      } else {
        //好友
        window.localStorage.setItem("editUser", Astrolabe.AstrolabeFileId);
        this.$store.state.userBeEdit = Astrolabe;
        this.$router.push({ path: "/friend-edit" });
      }
    },
    deleteFriend(Astrolabe) {
      if (Astrolabe.Relationship === "R0") return;
      this.friendBeDelete = Astrolabe.AstrolabeFileId;
      this.deleteDialog = true;
    },
    deleteFriendForSure() {
      this.deleteDialog = false;
      this.$API.Delete_AstrolabeFile(this.friendBeDelete).then((data) => {
        if (
          this.friendBeDelete == this.$store.state.nowUserData.AstrolabeFileId
        ) {
          this.$store.commit(
            "set_nowUserData",
            this.$store.state.userData.AstrolabeFriendLists[0]
          );
        }
        this.$API.Get_MemberInfo().then((data) => {
          window.localStorage["loginIndex"] = data.Data.MemberId;
          //顯示刪除好友泡泡
          this.showDelete = true;
          window.localStorage.setItem("loginIndex", data.Data.MemberId);
          this.$store.commit("set_userData", data.Data);
          this.init();
          setTimeout(() => {
            this.showDelete = false;
            window.localStorage.removeItem("friendState");
          }, 2000);
        });
      });
    },
  },
  watch: {
    $route(to) {
      if (!to.query.mode) {
        this.$store.commit("setLoading", true);
        setTimeout(() => {
          this.init();
          this.$store.commit("setLoading", false);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#selectTarget {
  padding-top: 20px;
  width: 100%;
  max-width: 989px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    @include pad {
      flex-direction: column;
      align-items: center;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      @include mobile {
        margin: 0 auto;
        font-size: 18px;
        line-height: 26px;
        justify-content: center;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include mobile {
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .filterBar {
      margin: 42px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        margin-top: 24px;
      }
      input,
      input:focus {
        width: 400px;
        height: 36px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        outline: none;
        padding: 8px 46px;
        @include mobile {
          max-width: 179px;
          height: 36px;
        }
      }
      input::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
      label {
        position: relative;
        .serachIcon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .filter_btn {
        @include btn-default(112px, 36px);
        border-radius: 100px;
        margin-left: 17px;
        @include mobile {
          @include btn-default(72px, 36px);
        }
      }
      .addFriends_btn {
        @include btn(40px, 40px, $theme-color-2, #fff);
        border-radius: 50%;
        margin-left: 30px;
        @include mobile {
          margin-left: 20px;
        }
        img {
          width: 90%;
        }
      }
    }
  }
  .st_mode_tab {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    & > div {
      width: 50%;
      height: 60px;
      border: 2px solid #798da5;
      color: #9b9b9b;
      cursor: pointer;
      @include center;
      @include mobile {
        height: 36px;
      }
    }
    & > div.active {
      background: #798da5;
      color: #fff;
    }
  }
  .st_list_group {
    width: 100%;
  }
  .next_contaner {
    .nextBtn {
      @include btn-default(375px, 64px);
      @include mobile {
        @include btn-default(250px, 56px);
        margin-bottom: 32px;
      }
    }
    @include mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 120px;
      @include center;
      backdrop-filter: blur(10px);
    }
  }
}
.TargetCard-space {
  margin-bottom: 20px;
  @include mobile {
    margin-bottom: 16px;
  }
}
</style>

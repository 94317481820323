var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "targetCard",
      class: { selected: _vm.selected && !_vm.unlock }
    },
    [
      _c("div", { staticClass: "pic_box" }, [
        _c("img", { attrs: { src: _vm.data.ProfilePhotoSrc, alt: "" } })
      ]),
      _c("div", { staticClass: "info_area" }, [
        _c("div", { staticClass: "main_group" }, [
          _c("div", { staticClass: "base" }, [
            _c("div", { staticClass: "name hidden-mobile-down" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$NameFormat(_vm.data.Name, 24)) +
                  "\n        "
              )
            ]),
            _c("div", { staticClass: "name hidden-mobile-up" }, [
              _vm._v(_vm._s(_vm.$NameFormat(_vm.data.Name)))
            ]),
            _c("div", { staticClass: "birthPlace hidden-mobile-down mb-2" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.data.BirthDate
                      ? _vm.data.BirthPlace
                      : _vm.data.BirthCountry + "," + _vm.data.BirthCity
                  ) +
                  "\n        "
              )
            ]),
            _c("div", { staticClass: "birthDay mb-2" }, [
              _vm.data.BirthDate
                ? _c("span", [
                    _c("span", { staticClass: "mr-2" }, [
                      _vm._v(_vm._s(_vm.data.BirthDate.split(" ")[0]))
                    ]),
                    _c(
                      "span",
                      { class: { "hidden-mobile-down": !_vm.unlock } },
                      [
                        _vm._v(
                          _vm._s(_vm.data.BirthDate.split(" ")[1]) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm.data.IsDaylight
                      ? _c("img", {
                          staticClass: "ml-1",
                          attrs: { src: require("@/assets/會員相關/sun.svg") }
                        })
                      : _vm._e()
                  ])
                : _c("span", [
                    _c("span", { staticClass: "mr-2" }, [
                      _vm._v(_vm._s(_vm.data.BirthDay))
                    ]),
                    _c(
                      "span",
                      { staticClass: "{'hidden-mobile-down': !unlock}" },
                      [_vm._v(_vm._s(_vm.data.BirthTime))]
                    ),
                    _vm.data.IsDaylight
                      ? _c("img", {
                          staticClass: "ml-3",
                          attrs: { src: require("@/assets/會員相關/sun.svg") }
                        })
                      : _vm._e()
                  ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.data.IsBelongToMember,
                    expression: "!data.IsBelongToMember"
                  }
                ],
                staticClass: "platform"
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.data.Platform) + "\n        "
                )
              ]
            )
          ]),
          _c("div", { staticClass: "control" }, [
            _c("div", { staticClass: "btn_group" }, [
              _vm.data.Relationship != "R0" && !_vm.closeEditMode
                ? _c("div", { staticClass: "select_btn" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon-delete.svg"),
                        title: "刪除"
                      },
                      on: {
                        click: function($event) {
                          return _vm.eventHandler("delete")
                        }
                      }
                    })
                  ])
                : _vm._e(),
              !_vm.closeEditMode
                ? _c("div", { staticClass: "select_btn" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon-edit.svg"),
                        title: "編輯"
                      },
                      on: {
                        click: function($event) {
                          return _vm.eventHandler("edit")
                        }
                      }
                    })
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "select_btn",
                  class: { active: _vm.selected },
                  on: {
                    click: function($event) {
                      return _vm.clicked()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/占星小鋪/select.svg"),
                      alt: ""
                    }
                  })
                ]
              )
            ])
          ])
        ]),
        !_vm.noTag
          ? _c("div", { staticClass: "unlock_group" }, [
              _vm.data.IsUnlockBC
                ? _c(
                    "div",
                    {
                      staticClass: "unlock_item",
                      on: {
                        click: function($event) {
                          return _vm.toService("宮位")
                        }
                      }
                    },
                    [_vm._m(0), _c("span", [_vm._v("觀看宮位解讀")])]
                  )
                : _vm._e(),
              _vm.IsUnlockLC
                ? _c(
                    "div",
                    {
                      staticClass: "unlock_item",
                      on: {
                        click: function($event) {
                          return _vm.toService("推運功能")
                        }
                      }
                    },
                    [_vm._m(1), _c("span", [_vm._v("觀看推運功能")])]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon_box" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/unlock.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon_box" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/unlock.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./FriendCard.vue?vue&type=template&id=8daa31ca&scoped=true&"
import script from "./FriendCard.vue?vue&type=script&lang=js&"
export * from "./FriendCard.vue?vue&type=script&lang=js&"
import style0 from "./FriendCard.vue?vue&type=style&index=0&id=8daa31ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8daa31ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/joe/Desktop/DaMou-Frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8daa31ca')) {
      api.createRecord('8daa31ca', component.options)
    } else {
      api.reload('8daa31ca', component.options)
    }
    module.hot.accept("./FriendCard.vue?vue&type=template&id=8daa31ca&scoped=true&", function () {
      api.rerender('8daa31ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/會員相關/FriendCard.vue"
export default component.exports